<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 급유/배유 내역 조회 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">

      <div slot="header">
        <BIconServer/> <strong> 급유/배유내역 조회 </strong>
        <div class="card-header-actions">
          <small class="text-muted">항공기 급유/배유 내역을 조회/전송 합니다</small>
        </div>
      </div>

      <BRow class="mb-2">
        <BCol sm="2">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="tid" :options="tankOpts" @input="getInoutData" style="background-color:#2f303a"/>
            <BInputGroupAppend>
              <BFormSelect size="sm" v-model="sts" :options="FuelStsOpts" style="background-color:#2f303a"
                           @input="getInoutData"/>
            </BInputGroupAppend>


          </BInputGroup>
        </BCol>
        <BCol sm="3">

          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="$store.state.volUnit" :options="con.unitVolOpts" style="background-color:#2f303a" @input="getInoutData"/>
            <BFormSelect size="sm" v-model="$store.state.lenUnit" :options="con.unitLenOpts" style="background-color:#2f303a" @input="getInoutData"/>
            <BFormSelect size="sm" v-model="$store.state.tmpUnit" :options="con.unitTmpOpts" style="background-color:#2f303a" @input="getInoutData"/>
          </BInputGroup>
        </BCol>
        <BCol>
          <BInputGroup size="sm">
            <BFormInput type="date" v-model="fromDts" @input="getInoutData"></BFormInput>
            <BFormInput type="date" v-model="toDts" @input="getInoutData"></BFormInput>
          </BInputGroup>
        </BCol>
        <BCol sm="2" class="text-right">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="$store.state.numOfInoutItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
            <BInputGroupAppend>
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getInoutData"><BIconArrowRepeat/></BButton>
              </BButtonGroup>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
      </BRow>

      <BRow>
        <BCol v-if="rows.length">
          <vue-excel-editor
            v-model="rows"
            ref="excelGrid"
            width="100%"
            class="mb-1"
            :page="$store.state.numOfInoutItem"
            :readonly-style="{backgroundColor:'#4F5040'}"
            @select="selectRecord"
            @update="updateCell"
            :localized-label="editorLabel"
            :key="editorKey"
            filter-row>
            <vue-excel-column field="_id"          type="string"   key-field invisible/>
            <vue-excel-column field="bgDt"         type="string"   width="140px" :label="cols['bgDt']" :to-text="toLocalTime" readonly/>
            <vue-excel-column field="edDt"         type="string"   width="140px" :label="cols['edDt']" :to-text="toLocalTime" readonly/>
            <vue-excel-column field="tcd"          type="string"   width="80px" label="코드" readonly/>
            <vue-excel-column field="tnm"          type="string"   width="80px" label="급유차명" readonly/>
            <vue-excel-column field="eqnm"          type="string"   width="80px" :label="cols['eqnm']" readonly/>
            <vue-excel-column field="eqid"          type="string"   width="80px" :label="cols['eqid']" readonly/>
            <vue-excel-column field="ano"          type="string"   width="80px" :label="cols['ano']" readonly/>
            <vue-excel-column field="sts"          type="map"     width="80px" :label="cols['sts']" :options="IoStsMap" readonly/>
<!--            <vue-excel-column field="targetVol"    type="number"  width="80px" :label="cols['targetVol']" :to-text="toCommaInt" readonly/>-->
            <!--                <vue-excel-column field="tid"        type="string"   width="80px" :label="cols['tid']" readonly/>-->
            <!--                <vue-excel-column field="use"        type="string"   width="80px" :label="cols['use']" readonly/>-->
            <vue-excel-column field="isDone"       type="map"      width="50px" :label="cols['isDone']" :options="{true:'완료', false:'작업중'}" readonly/>
            <vue-excel-column field="bgOhr"        type="number"   width="80px" :label="cols['bgOhr']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="bgOvm"        type="number"   width="80px" :label="cols['bgOvm']" :to-text="toCommaInt" readonly/>
<!--            <vue-excel-column field="bgOvc"        type="number"   width="80px" :label="cols['bgOvc']" :to-text="toCommaInt" readonly/>-->
            <vue-excel-column field="bgAvgTm"      type="number"   width="80px" :label="cols['bgAvgTm']" readonly/>

            <vue-excel-column field="adjOv"        type="number"   width="80px" :label="cols['adjOv']"/>
            <vue-excel-column field="inOvm"        type="number"   width="80px" :label="cols['inOvm']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="outOvm"        type="number"   width="80px" :label="cols['outOvm']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="adjRemark"    type="string"   width="120px" :label="cols['adjRemark']"/>
            <vue-excel-column field="opRemark"     type="string"   width="120px" :label="cols['opRemark']"/>


            <vue-excel-column field="edOhr"        type="number"   width="80px" :label="cols['edOhr']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="edOvm"        type="number"   width="80px" :label="cols['edOvm']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="edOvc"        type="number"   width="80px" :label="cols['edOvc']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="edAvgTm"      type="number"   width="80px" :label="cols['edAvgTm']" readonly/>
            <vue-excel-column field="diffOhr"      type="number"   width="80px" :label="cols['diffOhr']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="diffOvm"      type="number"   width="80px" :label="cols['diffOvm']" :to-text="toCommaInt" readonly/>
<!--            <vue-excel-column field="diffOvc"      type="number"   width="80px" :label="cols['diffOvc']" :to-text="toCommaInt" readonly/>-->



<!--            <vue-excel-column field="inOvc"        type="number"   width="80px" :label="cols['inOvc']" :to-text="toCommaInt" readonly/>-->

            <vue-excel-column field="batchDt"      type="string"   width="90px" label="전송일시" :to-text="toLocalYmd" readonly/>
            <vue-excel-column field="updId"        type="string"   width="80px" :label="cols['updId']"/>



          </vue-excel-editor>


        </BCol>
      </BRow>

      <BRow>
        <BCol>
          <BBadge variant="info">신규</BBadge> {{ selectedInfo.normal }}
          <BBadge varinat="danger">중복</BBadge> {{ selectedInfo.already }}
          <BBadge variant="dark">제외</BBadge> {{ selectedInfo.exclude }}
          <BButtonGroup>
            <BButton variant="warning"
                     size="sm"
                     class="ml-2 font-weight-bold"
                     :disabled="selectedRows.length===0"
                     @click="setConsumption">
              <BIconUpload/> 소모처리 등록
            </BButton>
            <BButton variant="info"
                     size="sm"
                     class="ml-1 font-weight-bold"
                     @click="showConsumptionModal">
              <BIconSearch/> 소모데이터 조회
            </BButton>

          </BButtonGroup>

        </BCol>
      </BRow>


      <BCard class="mt-2" v-if="row">
        <BRow>
          <BCol>
            <BBadge variant="info">급유차</BBadge> <BBadge variant="dark" class="mr-3">{{row?.tnm}}</BBadge>
            <BBadge variant="info">기간</BBadge> <BBadge variant="dark" class="mr-3">{{toLocalTime(row?.bgDt)}} ~ {{ toLocalTime(row?.edDt) }}</BBadge>
            <BBadge :variant="(row?.sts==='I')?'danger':'primary'">{{(row?.sts==='I')?'입고량':'출고량'}}</BBadge> <BBadge variant="dark">{{(row?.sts==='I')? toCommaInt(row?.inOvm): toCommaInt(row?.outOvm)}}</BBadge>

          </BCol>
        </BRow>

        <BRow>
          <BCol>
            <GraphInout :tid="row.tid"
                        :fromDts="row.bgDt"
                        :toDts="row.edDt"

            />
          </BCol>
        </BRow>
      </BCard>
    </BCard>


    <BModal id="con-detail"
            :class="{ 'c-dark-theme': true, animated: false, fadeIn: true }"
            ref="conDetail"
            body-bg-variant="dark"
            header-bg-variant="dark"
            footer-bg-variant="dark"
            body-text-variant="dark"
            header-text-variant="dark"
            footer-text-variant="dark"
            size="xl"
            title="소모처리 데이터"
            hide-header
            no-close-on-backdrop
            scrollable>
      <BTabs class="bg-dark">
        <BTab title="소모처리 데이터 조회" class="bg-dark">
          <CCard>
            <CCardHeader class="bg-dark">
              <BInputGroup size="sm" style="max-width:300px">
                <BFormInput type="date" v-model="batchDts" @input="getConsumption"/>
              </BInputGroup>
            </CCardHeader>
            <CCardBody class="bg-dark" style="min-height: 300px;">
              <vue-excel-editor
                v-if="conRows.length"
                v-model="conRows"
                ref="excelConGrid"
                width="100%"
                class="mb-1"
                :page="$store.state.numOfInoutItem"
                :readonly-style="{backgroundColor:'#4F5040'}"
                @update="updateConCell"
                @select="selectConRecord"
                :localized-label="editorLabel"
                :key="conEditorKey"
                filter-row>
                <vue-excel-column field="_id"                   type="string"   key-field invisible/>
                <vue-excel-column field="sendDt"                type="date"     width="140px"  label="전송일" :to-text="toLocalTime" readonly/>
                <vue-excel-column field="SPPRT_UC"              type="string"   width="140px" label="부대코드" />
                <vue-excel-column field="STRG_UC"               type="string"   width="140px" label="보급대대 코드"/>
                <vue-excel-column field="LOTNO"                 type="string"   width="80px" label="로트번호" />
                <vue-excel-column field="PRSC_DATE"	            type="string"   width="80px" label="시효일자" />
                <vue-excel-column field="MFGDT"	                type="string"   width="80px" label="제조일자" />
                <vue-excel-column field="FSC"	                  type="string"   width="110px" label="군급분류코드" />
                <vue-excel-column field="NIIN"	                type="string"   width="120px" label="국가품목식별번호" />
                <vue-excel-column field="BDGT_PRJ_CD"	          type="string"   width="245px" label="예산사업 코드" />
                <vue-excel-column field="SOCD"	                type="string"   width="90px" label="보급목적 코드" />
                <vue-excel-column field="INDVD_SN"	            type="string"   width="160px" label="개체일련번호"/>
                <vue-excel-column field="REFL_ISSU_RTNQTY"      type="number"   width="90px" label="급유량" readonly/>
                <vue-excel-column field="RCPNT_SRVNO"	          type="string"   width="140px" label="수령인군번" />
                <vue-excel-column field="RCPNT_MDCD"            type="string"   width="60px" label="수령인군 구분"/>
                <vue-excel-column field="RMRK"	                type="string"   width="80px" label="비고"/>
                <vue-excel-column field="REFL_VHCLNO"	          type="string"   width="90px" label="급유차량 번호"/>
                <vue-excel-column field="MCCD"                  type="string"   width="120px" label="물품상태코드"/>
                <vue-excel-column field="STRLOC"	              type="string"   width="160px" label="저장위치"/>
                <vue-excel-column field="UICD"	                type="string"   width="80px"  label="단위코드"/>
                <vue-excel-column field="WHSNO"	                type="string"   width="80px"  label="창고번호" />
                <vue-excel-column field="STRG_FCLTNO"	          type="string"   width="70px"  label="저장시설 번호" />
                <vue-excel-column field="TOC"	                  type="string"   width="70px"  label="거래사유 코드" />
                <vue-excel-column field="ORGNZT_UC"	            type="string"   width="120px"  label="편성부대코드" />
                <vue-excel-column field="RCPT_INDVD_SN"	        type="string"   width="90px"  label="수령장비 개체일련번호" />
                <vue-excel-column field="RCPT_FSC"	            type="string"   width="90px"  label="수령장비 군급분류코드" />
                <vue-excel-column field="RCPT_NIIN"	            type="string"   width="160px"  label="수령장비국가품목식별번호"/>
                <vue-excel-column field="ISSU_RTNDT"	          type="string"   width="90px"  label="불출반납일자"/>

              </vue-excel-editor>

              <BButton block variant="danger" size="sm" style="width:120px"
                       @click="delConRecord"
                       v-if="selConRows.length!==0">
                <BIconTrashFill/> 선택 삭제
              </BButton>

            </CCardBody>



          </CCard>

          <p v-if="!conRows.length" class="text-center text-warning">데이터 없음</p>


        </BTab>

        <BTab title="소모처리 코드 관리">

          <BTabs class="mt-2">
            <BTab title="장비코드">
              <EquipmentCode/>
            </BTab>

            <BTab title="거래사유">
              <TocCode/>
            </BTab>

            <BTab title="항공기">
              <AircraftCode/>
            </BTab>

            <BTab title="급유차">
              <TanklorryCode/>
            </BTab>

            <BTab title="고정코드">
              <StaticCode/>
            </BTab>

          </BTabs>

        </BTab>

      </BTabs>
    </BModal>



  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';

import {
  apiCall
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import con, {ExcelEditorLabel} from '@/common/constants'
import GraphInout from '../components/GraphInout';
import {InoutColMap, ConsumeColMap} from "@/common/schema";
import {IoOpts, IoStsMap, FuelStsOpts} from "@/common/constants";
import EquipmentCode from "@/views/components/deliisCodes/EquipmentCode.vue";
import TocCode from "@/views/components/deliisCodes/TocCode.vue";
import AircraftCode from "@/views/components/deliisCodes/AircraftCode.vue";
import TanklorryCode from "@/views/components/deliisCodes/TanklorryCode.vue";
import StaticCode from "@/views/components/deliisCodes/StaticCode.vue";

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankRealTime",
  components: {
    StaticCode,
    TanklorryCode,
    AircraftCode,
    TocCode,
    EquipmentCode,
    GraphInout
  },
  data () {
    return {
      con,
      fromDts: moment().format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      batchDts: moment().format('YYYY-MM-DD'),
      row: null,
      editorKey: 0,
      sts: '',
      arCode: '',
      tanks: [],
      tid: '',
      cols: InoutColMap,
      conCols: ConsumeColMap,
      IoOpts,
      FuelStsOpts,
      IoStsMap,
      tankOpts : [],
      tankCodes: this.$store.state.tanks['codes'],

      tank : null,
      tlgPacket: {},
      tankInstances : {},

      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,

      downloadReason: '',
      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],
      selectedRows: [],
      selectedInfo: { normal:0, exclude: 0, already:0},

      paramMap: {},
      numOfRecord: 10,
      updateKey: 0,

      modalTitle: '',

      conRows: [],
      selConRows: [],
      conEditorKey: 0,

    }

  },
  async created(){
    try{

      console.log("--- TankInOut creating ---------------------");


    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  async mounted() {
    console.log("--- TankInOut mounting---------------------");
    await this.getTanks();
    await this.getInoutData();


    // below is not work!
  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners

  },

  methods: {

    async getTanks(){
      try{

        this.tank = null;
        this.tankOpts = [{value: '', text: '전체 탱크'}];
        const r = await apiCall('get', `/api/tank/lorry`);
        if(r.code===200){
          r.result.map(t=>{
            this.tanks.push(t);
            this.tankOpts.push({ value: t.tid, text: `[${t.tid}] ${t.name}`} );
          })

          // await this.toastInfo(`탱크정보 ${this.tanks.length}건 조회`, 'info');

          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },

    async getInoutData(){
      this.rows = [];
      try{
        let qry ;

        this.paramMap = {
          tid: this.tid,
          sts: this.sts,
          fromDts: this.fromDts,
          toDts: this.toDts,
          volUnit: this.$store.state.volUnit,
          lenUnit: this.$store.state.lenUnit,
          tmpUnit: this.$store.state.tmpUnit
        };

        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        console.log( "query-string ---------- ", qry );

        const r = await apiCall('get', `/api/inout/consumption?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`급유/배유 현황 ${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }else{
          await this.toastResult( r );
        }
        // this.$refs['excelGrid'].clearFilter();

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },



    async selectRecord(idx, evt){
      try{
        if(!evt){
          this.row = null;
          return;
        }
        this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
        this.getSelectedInfo();
        if(this.selectedRows.length===1) {
          this.row = this.selectedRows[0];
        } else{
          this.row = null;
        }


      }catch(err){
        console.error(err)
      }
    },

    async selectConRecord(idx, evt){
      try{
        if(!evt){
          this.selConRows = [];
          return;
        }
        this.selConRows = this.$refs['excelConGrid'].getSelectedRecords();
      }catch(err){
        console.error(err)
      }
    },

    getSelectedInfo(){
      let cnt = { normal:0, exclude:0, already:0 };
      for(const r of this.selectedRows){
        if(r.isDone && r.batchDt===null ) cnt.normal++;
        if(!r.isDone) cnt.exclude++;
        if( r.isDone && r.batchDt!==null ) cnt.already++;
      }
      this.selectedInfo = cnt;
    },

    async setConsumption(){
      try{
        for(const r of this.selectedRows){
          if(!r.isDone || r.sts==='D') {
            const idx = this.$refs['excelGrid'].rowIndex[r.$id];
            this.$refs['excelGrid'].unSelectRecord(idx);
          }
          this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
        }
        if( this.selectedRows.length === 0 ) {
          await this.alertWarn('처리할 데이터가 없습니다.');
          return false;
        }

        const yn = await this.confirmModal(
          `총 ${this.selectedRows.length}개의 데이터카 소모처리자료로 등록 됩니다. 진행 하시게습니까?`,
          '항공기 소모처리 자료 등록 확인' );

        if(!yn) {
          await this.alertInfo('소모처리 자료 등록 취소');
          return false;
        }

        const rs = await apiCall("POST", `/api/consumption`, this.selectedRows);
        if(rs.code===200){
          await this.alertSuccess("소모처리 자료 등록완료: "+rs.result.count+"건");
          this.selectedInfo = { normal:0, exclude: 0, already:0};
        }else{
          await this.toastResult(rs);
        }
        // console.log(rs);

      }catch(err){
        console.error(err);
      }
    },

    showConsumptionModal(){
      this.$refs['conDetail'].show();
      this.getConsumption();
    },

    async getConsumption(){
      try{
        this.conRows = [];
        console.debug( "getConsumption-------->", this.batchDts );
        const rs = await apiCall("GET", `/api/consumption/${this.batchDts}`);
        if(rs.code===200){
          this.conRows = rs.result;
        }else{
          await this.toastResult(rs);
        }
        // console.log(rs);

      }catch(err){
        console.error(err);
      }
    },



    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        return;
      }


      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      console.log( "updateRecord--------- rec[0]|rc --->",rc );
      const idx = this.$refs['excelGrid'].rowIndex[rc.$id];
      console.log( "updateRecord--------- rowIndex--->",idx );
      console.log( "updateRecord--------- $refs --->", this.$refs['excelGrid'] );

      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      const cols = this.cols;
      let r = null;

      const param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        console.log( 'old value --->',rc.oldVal, 'new value --->', rc.newVal );

        console.log( '-----> will be updated to --->', param);
        r = await apiCall("PUT", `/api/inout/${objectId}`, param);
        console.log( r );
        if( r.code===200 ) {
          this.rows[idx] = r.result;
          this.editorKey++;
        }

        console.log( r );
        await this.toastResult(r, `${cols[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }

    },


    async updateConCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        return;
      }


      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      console.log( "updateRecord--------- rec[0]|rc --->",rc );
      const idx = this.$refs['excelConGrid'].rowIndex[rc.$id];
      console.log( "updateRecord--------- rowIndex--->",idx );
      // console.log( "updateRecord--------- $refs --->", this.$refs['excelConGrid'] );

      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      const cols = this.conCols;
      let r = null;

      const param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        console.log( 'old value --->',rc.oldVal, 'new value --->', rc.newVal );

        console.log( '-----> will be updated to --->', param);
        r = await apiCall("PUT", `/api/consumption/${objectId}`, param);
        console.log( r );
        if( r.code===200 ) {
          this.rows[idx] = r.result;
          this.conEditorKey++;
        }

        console.log( r );
        await this.toastResult(r, `${cols[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }

    },

    async delConRecord(){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.selConRows;
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) {
          return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');
        }

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelConGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/consumption/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelConGrid"].deleteRecord(tIndex);
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelConGrid'].clearAllSelected();
        this.selConRows = [];
        await this.getConsumption();

      }catch(err){

        console.log( err );
      }
    },




  }
}
</script>
<style>
.v-scroll-button {
  background-color: #888 !important;
  display: block !important;
  width: 12px;
  border-radius: 6px;
}

.v-scroll-button .runner {
  display: block !important;
}

.vue-excel-editor .table-content .systable .center-text .table-col-header {
  pointer-events: none;
}
</style>
