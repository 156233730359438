<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">


    <BCard header-tag="header" footer-tag="footer">

      <div slot="header" class="text-white">
        <BIconHddNetworkFill/> <strong > 급유차 관리 </strong>
        <div class="card-header-actions">
          <small class="text-muted">급유차를 관리합니다</small>
        </div>
      </div>

      <BRow class="mb-2">
        <BCol>
          <BInputGroup size="sm">
            <BButton size="sm" variant="primary" class="mr-3" @click="createCode">
              <BIconPatchPlusFill/>
              코드 생성
            </BButton>

            <BInputGroupAppend>
              <BFormSelect size="sm" v-model="searchField" :options="searchOpts" style="background-color:#2f303a"/>
              <BFormInput size="sm" v-model="searchWord" type="text" @keyup.enter="getCodeList"/>
            </BInputGroupAppend>

            <BButton size="sm" variant="info" @click="getCodeList">
              <BIconSearch/> 검 색
            </BButton>
          </BInputGroup>
        </BCol>

        <BCol sm="2" class="text-right">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="$store.state.numOfMachineItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
            <BInputGroupAppend>
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getCodeList"><BIconArrowRepeat/></BButton>
              </BButtonGroup>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
      </BRow>

      <BRow>
        <BCol>
          <vue-excel-editor
            v-model="rows"
            ref="excelGrid"
            width="100%"
            class="mb-1"
            :page="$store.state.numOfMachineItem"
            :readonly-style="{backgroundColor:'#4F5040'}"
            @update="updateCell"
            @select="selectRecord"
            :localized-label="excelEditorLabel"
            filter-row>

            <vue-excel-column field="_id"                     type="string"   key-field invisible/>
            <vue-excel-column field="mid"                     type="string"   width="70px" label='MID'/>
            <vue-excel-column field="tid"                     type="string"   width="70px"  label='TID'/>
            <vue-excel-column field="indvd_sn"                type="string"   width="180px" label='개체일련번호'/>
            <vue-excel-column field="refl_vhclno"             type="string"   width="80px" label='차량번호'/>
            <vue-excel-column field="strloc"                  type="string"   width="180px" label='저장위치'/>
            <vue-excel-column field="whsno"                   type="string"   width="120px" label='창고번호'/>
            <vue-excel-column field="strg_fcltno"             type="string"   width="150px" label='저장시설번호'/>
            <vue-excel-column field="remark"                  type="string"   width="150px" label='비고'/>
            <vue-excel-column field="updatedAt"               type="date"     width="150px" label='수정일시' :to-text="toLocalTime" readonly/>
            <vue-excel-column field="updId"                   type="string"   width="100px" label='수정자' readonly/>
            <vue-excel-column field="createdAt"               type="date"     width="150px" label='등록일시' :to-text="toLocalTime" readonly/>
            <vue-excel-column field="regId"                   type="string"   width="100px" label='등록자' readonly/>

          </vue-excel-editor>
        </BCol>
      </BRow>

      <BRow>
        <BCol>
          <BButtonGroup>
            <BButton variant="danger" class="ml-2" @click="deleteRecord" :disabled="selectedCodes===null">
              <BIconTrashFill/> 선택 정보 삭제
            </BButton>
          </BButtonGroup>
        </BCol>
        <BCol>
          <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
        </BCol>
      </BRow>
    </BCard>

  </div>
</template>


<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
  getRandomHex,
} from '@/common/utils';
import qs from 'querystring';
// import moment from "moment";
import {ExcelEditorLabel} from '@/common/constants'

const ColumnMap = {
  mid           : '급유차번호',
  tid           : 'TID',
  indvd_sn      : '개체일련번호',
  plate_no      : '차량번호',
  refl_vhclno   : '급유차차량번호',
  strloc        : '저장위치',
  whsno         : '창고번호',
  strg_fcltno   : '저장시설번호',
  remark        : '비고'
};

const Schema = {
  mid           : '',
  tid           : '',
  indvd_sn      : '',
  plate_no      : '',
  refl_vhclno   : '',
  strloc        : '',
  whsno         : '',
  strg_fcltno   : '',
  remark        : '',
};

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TanklorryCode",
  components: {

  },
  data () {
    return {
      maps: this.$store.state.codeMaps,
      selectedCodes: null,
      downloadReason: '',
      searchOpts: [
        {value:'refl_vhclno', text:'차량번호'},
        {value:'indvd_sn',  text:'개체일련번호'},
      ],
      searchField: 'refl_vhclno',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,

      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{

      await this.getCodeList();

    }catch(err){
      console.log(err);
    }
  },

  computed: {
    excelEditorLabel() {
      return ExcelEditorLabel
    }
  },

  mounted() {
    console.log("--- mounted---------------------");
    // below is not work!
  },

  methods: {

    async createCode(){
      let newCode;
      try{

        if( !await this.confirmModal(`신규코드를 등록합니다. 진행 하시겠습니까?`, '코드정보 등록') ){
          await this.alertModal( '코드등록 취소', 'DELIIS 코드정보');
          return;
        }else{
          newCode = cloneVar(Schema);
        }

        if(this.selectedCodes!==null){
          if( await this.confirmModal(`선택항목을 복제하여 등록합니다. 진행하시겠습니까?`, 'DELIIS 코드정보') ){
            newCode = cloneVar(this.selectedCodes); // 복제등록 _id 제거, 제거안하면 duplication error
            delete newCode._id;
            newCode.updId = '';
          }
        }

        newCode.eqcd = getRandomHex(4);


        const r = await apiCall('post', `/api/equipment/tank-lorry`, newCode);

        if( r.code===200){
          await this.alertModal( '코드가 등록되었습니다', 'DELIIS 코드정보', 'success');
          await this.getCodeList();
        }else{
          await this.alertModal('코드 등록 실패: '+r.message,'DELIIS 코드정보', 'warning');
        }

      }catch(err){
        console.log( err );
        await this.alertModal('코드 등록 실패:'+err.message,'DELIIS 코드정보', 'danger');
      }

    },

    async getCodeList(){
      try{
        let qry = '';
        this.paramMap = {};
        this.progCount = 0;
        this.totalCount = 0;
        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        console.log( "query-string ---------- ", qry );


        this.rows = [];
        const r = await apiCall('get', `/api/equipment/tank-lorry?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'INFORMATION', 'info',  false);
        }

        if(this.$refs['excelGrid']) this.$refs['excelGrid'].clearFilter();

        this.selectedCodes = null;
      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },


    async selectRecord(idx, evt){
      if(!evt){
        // this.tankFormShow = false;
        this.selectedCodes = null;

        return;
      }

      this.selectedCodes = this.$refs['excelGrid'].getSelectedRecords().pop();

      console.log( 'select idx, tank --->', idx, this.selectedCodes );

    },



    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        alert( "KEY_NOT_FOUND" );
        return;
      }
      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // const row = this.$refs['excelGrid'].currentRecord;
        console.log( 'param --->', param);
        r = await apiCall("PUT", `/api/equipment/tank-lorry/${objectId}`, param);
        // console.log( r );
        await this.toastResult(r, `${ColumnMap[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }

    },

    async deleteRecord(recs){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        console.log( "deleteRecord --- recs ---", recs.length );
        console.log( "deleteRecord --- params ", params );
        console.log( "deleteRecord length ----> ", params.length );

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/equipment/tank-lorry/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }else{
            failCnt++;
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        await this.getCodeList();

      }catch(err){

        console.log( err );
      }
    },


  }
}
</script>
